<template>
  <div class="performance-detail">
    <!-- <div class="detaile-desc">注：统计日期为业绩数据同步时间，数据以银行业务数据为准</div> -->
    <performanceInformationCell :stationNo="$route.query.stationNo" class="deatils_cell"></performanceInformationCell>
  </div>
</template>

<script>
import performanceInformationCell from "./performanceInformationCell";
import {uwStatisticAction} from "../../../utils/util";
export default {
  components: {
    performanceInformationCell
  },
  data() {
    return{

    }
  },
  mounted(){
    uwStatisticAction('/performanceDetail','业绩统计详情')

  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.performance-detail{
  height: 100%;
  .detaile-desc {
    font-size: 12px;
    color: #999999;
    margin: 0.2rem 0.2rem 0rem 0.2rem;
  }
}
</style>